import { React, useState, useEffect, lazy, Suspense } from "react";
import { Link, NavLink } from "react-router-dom";
import styled, { ThemeProvider, keyframes } from 'styled-components'
import { motion } from "framer-motion";
import { Mail } from "./AllSvgs";
import Intro from "./Intro";
import Me from '../assets/Images/headshot.jpg';
import ThemeSwitch from "../subComponents/ThemeSwitch";
import { darkTheme, lightTheme, mediaQueries } from "./Themes";
import Loader from "../subComponents/Loader";

const SocialIcons = lazy(() => import("../subComponents/SocialIcons"));
const HomeButton = lazy(() => import("../subComponents/LogoComponent"));
const LogoComponent = lazy(() => import("../subComponents/LogoComponent"));


const MainContainer = styled.div`
background: ${props => props.theme.body};
width: 100vw;
height: 100vh;
overflow: hidden;

position: relative;

h3,h3,h4,h5,h6{
    font-family: 'Karla', sans-serif ;
    font-weight: 500;
}

h2 {
    ${mediaQueries(40)`
      font-size:1.2em;

  `};

    ${mediaQueries(30)`
      font-size:1em;

  `};
  }

`


const Container = styled.div`
padding: 2rem;

`

const Contact = styled(NavLink)`
color: burlywood;
position: absolute;
top: 3rem;
padding: .1rem;
right: calc(1rem + 2vw);
text-decoration: none;
z-index: 1;
border-radius: .5rem;
transition: .2s;

&:hover{
    transform: scale(1.1);
    box-shadow: 0 0 2px 2px burlywood;
}

`

const Projects = styled(NavLink)`
color: ${props => props.click ? props.theme.body : props.theme.text};
position: absolute;
top: 42%;
left: calc(.5vw);
transform: rotate(-90deg) translate(-50%, 0);
text-decoration: none;
background-color: burlywood;
padding: .5rem;
border-radius: .5rem;
z-index: 1;
transition: .2s;

&:hover{
    transform: scale(1.1);
    box-shadow: 0 0 2px 2px burlywood;
}

@media only screen and (max-width: 50em) {
    text-shadow: ${(props) => (props.click ? "0 0 4px #000" : "none")};
  }

`

const ProjWrap = styled(motion.div)`
position: absolute;
top: 42%;
left: 0;
transform: rotate(-90deg) translate(-50%, 0);
text-decoration: none;
background-color: none;
padding: 0;
border-radius: .5rem;
z-index: 1;
transition: .2s;

@media only screen and (max-width: 50em) {
    text-shadow: ${(props) => (props.click ? "0 0 4px #000" : "none")};
  }

`

const Skills = styled(NavLink)`
color: ${props => props.theme.text};
position: absolute;
top: 50%;
right: calc(1rem + 2vw);
transform: rotate(90deg) translate(-50%, -50%);
text-decoration: none;
background-color: burlywood;
padding: .5rem;
border-radius: .5rem;
z-index: 1;
transition: .2s;

&:hover{
    transform: scale(1.1);
    box-shadow: 0 0 2px 2px burlywood;
}

@media only screen and (max-width: 50em) {
    text-shadow: ${(props) => (props.click ? "0 0 4px #000" : "none")};
  }

`
const SkillsWrap = styled(motion.div)`
position: absolute;
top: 50%;
right: 0;
transform: rotate(90deg) translate(-50%, -50%);
text-decoration: none;
background-color: none;
padding: 0;
border-radius: .5rem;
z-index: 1;
transition: .2s;

@media only screen and (max-width: 50em) {
    text-shadow: ${(props) => (props.click ? "0 0 4px #000" : "none")};
  }

`


const BottomBar = styled(motion.div)`
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    width: 100%;

    display: flex;
    justify-content: space-evenly;

`

const About = styled(NavLink)`
color: ${props => props.click ? props.theme.body : props.theme.text};
text-decoration: none;
background-color: burlywood;
padding: .5rem;
border-radius: .5rem;
z-index: 1;
transition: .2s;

&:hover{
    transform: scale(1.1);
    box-shadow: 0 0 2px 2px burlywood;
}
`

const Resume = styled.a`
color: ${props => props.theme.text};
text-decoration: none;
background-color: burlywood;
padding: .5rem;
border-radius: .5rem;
z-index: 1;
transition: .2s;

&:hover{
    transform: scale(1.1);
    box-shadow: 0 0 2px 2px burlywood;
}
`

const throb = keyframes`
0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`


const Center = styled.button`
    position: absolute;
    top: ${props => props.click ? '85%' : '50%'};
    left: ${props => props.click ? '92%' : '50%'};
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 1s ease;

    &>:first-child{
        animation: ${throb} infinite .8s cubic-bezier(0.42, 0, 0.58, 1);
    }

    &>:last-child{
        display: ${props => props.click ? 'none' : 'inline-block'};
        padding-top: 1rem;
    }

    @media only screen and (max-width: 50em) {
        top: ${(props) => (props.click ? "90%" : "50%")};
        left: ${(props) => (props.click ? "90%" : "50%")};
        width: ${(props) => (props.click ? "80px" : "150px")};
        height: ${(props) => (props.click ? "80px" : "150px")};
      }
      @media only screen and (max-width: 30em) {
        width: ${(props) => (props.click ? "40px" : "150px")};
        height: ${(props) => (props.click ? "40px" : "150px")};
      }

`

const DarkDiv = styled.div`
position: absolute;
top: 0;
bottom: 0;
background-color: #000000; 
right: 50%;
width: ${props => props.click ? '50%' : '0%'};
height: ${props => props.click ? '100%' : '0%'};
z-index: 1;
transition: height 0.5s ease, width 1s ease 0.5s;

${(props) =>
    props.click ? mediaQueries(50)`
        height: 50%;
        right:0;
        width: 100%;
        transition: width 0.5s ease, height 1s ease 0.5s;
  `
: mediaQueries(50)`
    height: 0;
    width: 0;
  `};

`



const Main = () => {

    const[click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [selectedTheme, setSelectedTheme] = useState(lightTheme);

    useEffect(() => {
      const currentTheme = JSON.parse(localStorage.getItem("current-theme"));
      if (currentTheme) {
        setSelectedTheme(currentTheme);
      }
    }, [])

    
    const mq = window.matchMedia("(max-width: 50em)").matches;

    return (
        <ThemeProvider theme={selectedTheme}>
            <Suspense fallback={Loader}>
                <MainContainer>
                
                    <DarkDiv click={click} />
                    <Container>
                        <ThemeSwitch selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
                        <HomeButton />
                        <LogoComponent theme={(selectedTheme === darkTheme || click) ? 'dark' : 'light'} />
                        {mq ? (
                            <SocialIcons theme={(selectedTheme === darkTheme) ? 'dark' : 'light'} />
                            ) : (
                            <SocialIcons theme={(selectedTheme === darkTheme || click) ? 'dark' : 'light'} />
                        )}


                        <Center click={click}>
                            {mq ? (
                                <img src={Me} alt="me" 
                                    style={{ borderRadius: "50%" }}
                                    onClick={()=> handleClick()}
                                    width={click ? '50' : '150'}
                                    height={click ? '50' : '150'}
                                    fill='currentColor'
                                />
                                ) : (
                                    <img src={Me} alt="me" 
                                    style={{ borderRadius: "50%" }}
                                    onClick={()=> handleClick()}
                                    width={click ? '80' : '200'}
                                    height={click ? '80' : '200'}
                                    fill='currentColor'
                                />
                                )}
                            <span>Click Me</span>
                        </Center>

                        {mq ? (
                            <Contact
                                click={+click}
                                target="_blank"
                                to={{pathname: "mailto:coderboy.exe@gmail.com"}}
                            >
                                <Mail width={30} height={30} fill='currentColor' />
                            </Contact>
                            ) : (
                                <Contact
                                click={+false}
                                target="_blank"
                                to={{pathname: "mailto:coderboy.exe@gmail.com"}}
                            >
                                <Mail width={30} height={30} fill='currentColor' />
                            </Contact>
                        )}

                        {mq ? (
                            <SkillsWrap
                                click={+click}
                                initial={{
                                    y:500,
                                    transition: { type:'spring', duration: 1.5, delay: 1 }
                                }}
                                animate={{
                                    y:0,
                                    transition: { type:'spring', duration: 1.5, delay: 1 }
                                }}
                                whileHover={{scale: 1.1}}
                                whileTap={{scale: 0.9}}
                            >     
                                <Skills to='/skills'>
                                    <motion.h3>
                                        Skills
                                    </motion.h3>
                                </Skills>
                            </SkillsWrap>
                            ) : (
                            <SkillsWrap
                                click={+false}
                                initial={{
                                    y:500,
                                    transition: { type:'spring', duration: 1.5, delay: 1 }
                                }}
                                animate={{
                                    y:0,
                                    transition: { type:'spring', duration: 1.5, delay: 1 }
                                }}
                                whileHover={{scale: 1.1}}
                                whileTap={{scale: 0.9}}
                            >     
                                <Skills to='/skills'>
                                    <motion.h3>
                                        Skills
                                    </motion.h3>
                                </Skills>
                            </SkillsWrap>
                        )}
                        
                        
                        <ProjWrap
                            initial={{
                                y:-500,
                                transition: { type:'spring', duration: 1.5, delay: 1 }
                            }}
                            animate={{
                                y:0,
                                transition: { type:'spring', duration: 1.5, delay: 1 }
                            }}
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 0.9}}
                        >
                            <Projects to='/projects' click={click}>
                                <motion.h3>
                                    Projects
                                </motion.h3>
                            </Projects>
                        </ProjWrap>

                        <BottomBar
                            initial={{
                                y:200,
                                transition: { type:'spring', duration: 1.5, delay: 1 }
                            }}
                            animate={{
                                y:0,
                                transition: { type:'spring', duration: 1.5, delay: 1 }
                            }}
                        >
                            <About to='/about' click={click}>
                                <h3>
                                    About
                                </h3>
                            </About>
                            <Resume target="_blank" href='https://docs.google.com/document/d/1xz24OnkukkRiIt19_M3MgGIhIkAHdAvpwx2StqDzVLI/edit?usp=sharing'>
                                <h3>
                                    Resume
                                </h3>
                            </Resume>
                        </BottomBar>


                    </Container>
                    {click ? <Intro click={click} /> : null}

                </MainContainer>
            </Suspense>
        </ThemeProvider>
    )
}

export default Main