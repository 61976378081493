import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import {darkTheme} from '../components/Themes';
import {Medium, Github, LinkedIn, Twitter, YouTub, Medi, LinkedInume } from '../components/AllSvgs';
import { motion } from 'framer-motion';


const Icons = styled.div`
display: flex;
flex-direction: column;
align-items: center;

position: fixed;
bottom: 0;
left: 1.5rem;
transition: 2s ease;

z-index: 3;

&>*:not(:last-child) {
    margin: 0.5rem 0;
}


`

const Line = styled(motion.span)`
width: .2rem;
height: 3rem;
background-color: ${props => props.color === 'dark' ? darkTheme.text : darkTheme.body};
transition: 2s ease;
`


const SocialIcons = (props) => {
    return (
        <Icons>
            <motion.div
                initial={{transform:'scale(0)'}}
                animate={{scale:[0,1,1.2,1]}}
                transition={{type:'spring', duration:1, delay:1}}
            >
                <NavLink style={{color: 'inherit'}} target="_blank" to={{pathname:"https://github.com/coderboy-exe"}}>
                    <Github width={25} height={25} fill={props.theme === "dark" ? darkTheme.text : darkTheme.body} />
                </NavLink>
            </motion.div>
            <motion.div
                initial={{transform:'scale(0)'}}
                animate={{scale:[0,1,1.2,1]}}
                transition={{type:'spring', duration:1, delay:1.2}}
            >
                <NavLink style={{color: 'inherit'}}target="_blank" to={{pathname:"https://twitter.com/coderboy_exe"}}>
                    <Twitter width={25} height={25} fill={props.theme === "dark" ? darkTheme.text : darkTheme.body} />
                </NavLink>
            </motion.div>
            <motion.div
                initial={{transform:'scale(0)'}}
                animate={{scale:[0,1,1.2,1]}}
                transition={{type:'spring', duration:1, delay:1.4}}
            >
                <NavLink style={{color: 'inherit'}} target="_blank" to={{pathname:"https://medium.com/@usottah"}}>
                    <Medium width={25} height={25} fill={props.theme === "dark" ? darkTheme.text : darkTheme.body} />
                </NavLink>
            </motion.div>
            <motion.div
                initial={{transform:'scale(0)'}}
                animate={{scale:[0,1,1.2,1]}}
                transition={{type:'spring', duration:1, delay:1.6}}
            >
                <NavLink style={{color: 'inherit'}} target="_blank" to={{pathname:"https://linkedin.com/in/uchechukwu-ottah-92968a162"}}>
                    <LinkedIn width={25} height={25} fill={props.theme === "dark" ? darkTheme.text : darkTheme.body} />
                </NavLink>
            </motion.div>

            <Line color={props.theme} 
                initial={{
                    height:0,
                }}
                animate={{
                    height:'3rem',
                }}
                transition={{type: 'spring', duration: 1, delay: 0.8}}
            />
        </Icons>
    )
}

export default SocialIcons