import React, { lazy } from "react";
import styled, {ThemeProvider, keyframes} from 'styled-components';

import {lightTheme, darkTheme, mediaQueries} from './Themes';
import {Blockchain, CssLogo, Develope, DjangoLogo, FigmaLogo, GitLogo, HtmlLogo, JsLogo, PythonLogo, ReactLogo, SCLogo, SolidityLogo, TailwindLogo, VsCodeLogo, VueLogo} from './AllSvgs';
import img from "../assets/Images/nasa-yZygONrUBe8-unsplash.jpg";
import Nav, { AboutNav, ContactNav, IconNav, ProjectsNav, SkillsNav } from "./Nav";
import { motion } from "framer-motion";
import { Suspense } from "react/cjs/react.production.min";
import Loader from "../subComponents/Loader";

import api from '../assets/Images/api.png'
import C_logo from '../assets/Images/C_Logo.png'
import django from '../assets/Images/django.png'
import flask from '../assets/Images/flask.png'
import mysql from '../assets/Images/mysql.png'
import bash from '../assets/Images/bash.png'
import docker from '../assets/Images/docker.png'
import nginx from '../assets/Images/nginx.png'
import nodejs from '../assets/Images/nodejs.png'
import postgres from '../assets/Images/postgres.png'
import unix from '../assets/Images/unix.png'
import nextjs from '../assets/Images/nextjs.png'
import python from '../assets/Images/python.png'


// Lazy load imports
const HomeButton = lazy(() => import("../subComponents/HomeButton"));
const LogoComponent = lazy(() => import("../subComponents/LogoComponent"));
const SocialIcons = lazy(() => import("../subComponents/SocialIcons"));



const Container = styled.div`
background-image: url(${img});
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
`

const Box = styled(motion.div)`
background-color: ${props => `rgba(${props.theme.bodyRgba}, 0.7)`};
width: 100vw;
height: 100vh;
position: relative;
display: flex;
justify-content: center;
align-items: center;

    ${mediaQueries(50)`
    flex-direction: column;  
    padding: 8rem 0;
    height: auto;
    &>*:nth-child(5){
    margin-bottom: 5rem;
    }

    `};
    ${mediaQueries(30)`

    &>*:nth-child(5){
    margin-bottom: 4rem;
    }

    `};

`

const Main = styled(motion.div)`
border: .2rem solid ${props => props.theme.text};
color: ${props => props.theme.text};
background-color: ${props => `rgba(${props.theme.textRgba}, 0.3)`};
padding: 2rem;
width: 32vw;
height: 60vh;
z-index: 2;
line-height: 1.5;
margin: 0 .5rem;

    ${mediaQueries(60)`
    height: 55vh;
    `};

    ${mediaQueries(50)`
    width: 50vw;
    height: max-content;
    margin-bottom: 2rem;

    `};

font-family: 'Ubuntu Mono', monospace;
display: flex;
flex-direction: column;
justify-content: space-between;

`

const Title = styled.h2`
display: flex;
justify-content: center;
align-items: center;
font-size: calc(1em + .7vw);

    ${mediaQueries(60)`
    font-size:calc(0.8em + 1vw);
    `};

    ${mediaQueries(50)`
    font-size:calc(1em + 2vw);
    margin-bottom:1rem;
    `};

    ${mediaQueries(30)`
                font-size:calc(1em + 1vw);
    `};
    ${mediaQueries(25)`
                font-size:calc(0.8em + 1vw);
                svg{
                width: 30px;
                height: 30px;
                }
    `};

    ${Main}:hover & {
    & > * {
    fill: ${(props) => props.theme.body};
    }
    }

    & > *:first-child {
    margin-right: 1rem;
    }

`

const Description = styled.div`
color: ${props=> props.theme.text};
font-size: calc(0.5em + .6vw);
padding: 0.5rem 0;
text-align: justify;
text-justify: inter-word;

    ${Main}:hover & {
        color: burlyood;
    }

    ${mediaQueries(50)`
        font-size: calc(0.8em + 1vw);
    `};

    ${mediaQueries(30)`
        font-size:calc(0.7em + 1vw);     
    `};

    ${mediaQueries(25)`
        font-size:calc(0.5em + 1vw);
    `};

    strong {
        margin-bottom: 1rem;
        text-transform: uppercase;
    }
    ul,
    p {
        margin-left: 2rem;
    }

`

const TechStack = styled.div`
display: flex;
padding: 1rem;
justify-content: space-between;
align-items: center;
padding-bottom: 2rem

`




const Skills = () => {
    return (
        <ThemeProvider theme={darkTheme}>
            <Suspense fallback={<Loader />}>
                <Container>
                    <Box>
                        <LogoComponent theme='dark'/>
                        <SocialIcons theme='dark'/>
                        <HomeButton />
                        <ContactNav />
                        <ProjectsNav theme='dark'/>
                        <AboutNav theme='dark'/>
                        <IconNav />

                        <Main 
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1, delay: .5 } }}
                        >
                            <Title>
                                Fullstack Engineer
                            </Title>
                            <Description>
                                As a backend-heavy fullstack engineer,
                                I possess a comprehensive toolkit to develop exceptional web applications. 
                                With React, I can create interactive and responsive user interfaces that provide a seamless user experience.
                                I also have a strong command of Python and popular frameworks such as Flask and Django, 
                                which enable me to create robust and scalable web applications, from simple prototypes to complex systems.
                                I'm currently learning Node.js and Express.js im order to expand my arsenal. 
                                My extensive knowledge of both frontend and backend technologies allows me to create web applications that are not only visually stunning but also powerful and efficient.
                            </Description>
                            <Description>
                              
                            </Description>
                        </Main>

                        <Main
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }}
                        >
                            <Title>
                                Some of the tools I use include:
                            </Title>
                            <Description
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 1, delay: 1.5 } }}
                            >
                                <TechStack>
                                    <ReactLogo width={40} height={40} />
                                    <img src={nextjs} alt="tools" width={40} height={40} />
                                    <SCLogo width={40} height={40} />
                                    <TailwindLogo width={40} height={40} />
                                </TechStack>
                                <TechStack>
                                    <VsCodeLogo width={40} height={40} />
                                    <img src={python} alt="tools" width={40} height={40} />
                                    <JsLogo width={40} height={40} />
                                    <img src={nodejs} alt="tools" width={40} height={40} />
                                </TechStack>
                                <TechStack>
                                    <img src={api} alt="tools" width={40} height={40} />
                                    <img src={postgres} alt="tools" width={40} height={40} />
                                    <img src={docker} alt="tools" width={40} height={40} />
                                    <GitLogo width={40} height={40} />
                                </TechStack>
                                <TechStack>
                                    <img src={django} alt="tools" width={40} height={40} />
                                    <img src={flask} alt="tools" width={40} height={40} />
                                    <img src={nodejs} alt="tools" width={40} height={40} />
                                    <img src={mysql} alt="tools" width={40} height={40} />
                                </TechStack>
                                <TechStack>
                                    <img src={C_logo} alt="tools" width={40} height={40} />
                                    <img src={unix} alt="tools" width={40} height={40} />
                                    <img src={nginx} alt="tools" width={40} height={40} />
                                    <img src={bash} alt="tools" width={40} height={40} />
                                </TechStack>
                            </Description>
                            <Description>

                            </Description>
                        </Main>
                    </Box>
                </Container>
            </Suspense>
        </ThemeProvider>

    )
}

export default Skills