import {React, useState} from 'react'
import styled from 'styled-components'
import { darkTheme, lightTheme } from '../components/Themes';


const Box = styled.div`
display: flex;
cursor: pointer;
position: fixed;
left: 8rem;
top: 3rem;
z-index: 5;
`

const ThemeButton = styled.button`
  margin: 0 5px;
  padding: 10px;
  font-size: 0.5rem;
  border: 1px solid hsl(0, 0%, 87%);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px 2px hsl(0, 0%, 87%);
`;


const ThemeSwitch = ({ selectedTheme, setSelectedTheme }) => {

  const handleThemeChange = (theme) => {
      setSelectedTheme(theme)
      localStorage.setItem("current-theme", JSON.stringify(theme))
  }

  return (
    <Box>
      <span>Themes: </span>
        <ThemeButton 
          className={`light ${selectedTheme === lightTheme ? "active" : ""}`}
          onClick={() => handleThemeChange(lightTheme)}
        >

        </ThemeButton>

        <ThemeButton
          className={`dark ${selectedTheme === darkTheme ? "active" : ""}`}
          onClick={() => handleThemeChange(darkTheme)}
        >

        </ThemeButton>
    </Box>
  )
}

export default ThemeSwitch