import { Route, Switch } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { lightTheme, darkTheme } from "./components/Themes"
import GlobalStyle from "./globalStyles"

import Main from "./components/Main";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import { useEffect, useState } from "react";


function App() {

  
  const [selectedTheme, setSelectedTheme] = useState(lightTheme);

  useEffect(() => {
    const currentTheme = JSON.parse(localStorage.getItem("current-theme"));
    if (currentTheme) {
      setSelectedTheme(currentTheme);
    }
  }, [])

  return <>

  <GlobalStyle />

    <ThemeProvider  theme={selectedTheme}>

      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/about" component={About} />
        <Route exact path="/skills" component={Skills} />
        <Route exact path="/projects" component={Projects} />
      </Switch>
    </ThemeProvider>
    </>
    
}

export default App

