import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import Spaceman from '../assets/Images/spaceman.png'
import { Github } from "./AllSvgs";


const Box = styled(NavLink)`
width: calc(10rem + 15vw);
text-decoration: none;
height: 20rem;
padding: 1rem;
color: ${props => props.theme.text};
border: .2rem solid ${props => props.theme.text};
backdrop-filter: blur(.2rem);
box-shadow: 0 0 1rem 0 rgba(0,0,0,0.2);
cursor: pointer;
border-radius: 2rem;

display: flex;
flex-direction: column;
z-index: 1;

&:hover {
    color: ${props => props.theme.body};
    background-color: ${props => props.theme.text};
    transition: all 0.5s ease;
}
`

const Image = styled.div`
background-image: ${`url(${Spaceman})`};
width: 100%;
height: 60%;
background-size: cover;
border: .1rem solid transparent;
background-position: center center; 

${Box}:hover & {
    border: 0.1rem solid ${props => props.theme.body};
}
`
const Title = styled.h3`
color: inherit;
padding: 0.5rem 0;
padding-top: 1rem;
font-family: 'Karla', sans-serif;
font-weight: 700;
border-bottom: .1rem solid ${props => props.theme.text};

${Box}:hover & {
    border-bottom: 0.1rem solid ${props => props.theme.body};
    color: ${props => props.theme.body};
    background-color: ${props => props.theme.text};
}
`

const Hashtags = styled.div`
padding: 0.5rem 0;
`

const Tag = styled.span`
padding-right: 0.5rem;
`

const Description = styled.span`
padding: 0.5rem 0;
`
const Footer = styled.footer`
display: flex;
justify-content: space-between;
`

const Live = styled(NavLink)`
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};
text-decoration: none;
padding: 0.5rem;
border-radius: 20%;
font-size: 1rem;

${Box}:hover & {
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.body};
}
`

const Git = styled(NavLink)`
&>*{
    fill: ${props => props.theme.body}
}
background-color: ${props => props.theme.text};
text-decoration: none;
padding: 0.2rem;
border-radius: 20%;
font-size: 1rem;

${Box}:hover & {
    &>*{
        fill: ${props => props.theme.text}
    }
    background-color: ${props => props.theme.body};
}
`




const ProjectComponent = (props) => {

    const { name, description, tags, demo, github } = props.project;

    return (
        <Box target="_blank" to={{pathname: demo }}>
            <Image />
            <Title>{name}</Title>
            <Hashtags>
                {
                    tags.map((t,id) => {
                        return <Tag key={id}> #{t} </Tag>
                    })
                }
            </Hashtags>
            <Description>
                {description}
            </Description>
            <Footer>
                <Live target="_blank" to={{pathname: demo }}>
                    Live
                </Live>
                <Git target="_blank" to={{pathname: github }}>
                    <Github width={30} height={30} />
                </Git>

            </Footer>
        </Box>
    )
}

export default ProjectComponent