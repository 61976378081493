import React, { lazy, Suspense } from 'react'
import styled, { keyframes, ThemeProvider } from 'styled-components'
import { darkTheme, mediaQueries } from './Themes';

import img from "../assets/Images/nasa-yZygONrUBe8-unsplash.jpg";
import astronaut from '../assets/Images/spaceman.png'
import { AboutNav, ContactNav, IconNav, ProjectsNav, SkillsNav } from './Nav';
import { motion } from 'framer-motion';
import Loader from '../subComponents/Loader';

// Lazy load imports
const HomeButton = lazy(() => import("../subComponents/HomeButton"));
const LogoComponent = lazy(() => import("../subComponents/LogoComponent"));
const SocialIcons = lazy(() => import("../subComponents/SocialIcons"));



const Container = styled(motion.div)`
background-image: url(${img});
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
`

const Box = styled.div`
background-color: ${props => `rgba(${props.theme.bodyRgba}, 0.7)`};
width: 100vw;
height:100vh;
position: relative;
overflow: hidden;
`

const float = keyframes`
0% { transform: translateY(-10px) }
50% { transform: translateY(15px) translateX(15px) }
100% { transform: translateY(-10px) }
`

const Spaceman = styled(motion.div)`
position: absolute;
top: 10%;
right: 5%;
width: 20vw;
animation: ${float} 4s ease infinite;
img{
    width: 100%;
    height: auto;
}
`

const Main =  styled(motion.div)`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  padding: 2rem;
  width: 50vw;
  height: 60vh;
  z-index: 3;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.6rem + 1vw);
 backdrop-filter: blur(4px);
  
  position: absolute;
  left: calc(5rem + 5vw);
  top: 10rem;
  font-family: 'Ubuntu Mono', monospace;
  font-style: italic;

  ${mediaQueries(40)`
  width: 60vw;
  height: 50vh;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);


`};
${mediaQueries(30)`
  width: 50vw;
  height: auto;
  backdrop-filter: none;
  margin-top:2rem;

`};

${mediaQueries(20)`
  padding: 1rem;
  font-size: calc(0.5rem + 1vw);
`};

`




const AboutPage = () => {
    return (
        <ThemeProvider theme={darkTheme}>
            <Suspense fallback={<Loader />}>    
                <Container>
                    <Box
                        key='skills'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                        exit={{ opacity: 0, transition: { duration: 0.5 } }}
                    >
                        <LogoComponent theme='dark' />
                        <HomeButton />
                        <SocialIcons theme='dark' />
                        <ContactNav />
                        <SkillsNav theme='dark' />
                        <ProjectsNav theme='dark' />
                        <AboutNav theme='dark' />
                        <IconNav />
                        <Spaceman
                            initial={{ right: '-20%', top: '100%' }}
                            animate={{
                              right: '5%',
                              top: '10%',
                              transition: { duration: 2, delay: 0.5 },
                            }}
                        >
                            <img src={astronaut} alt="spaceman" />
                        </Spaceman>    
                        <Main
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }}
                        >
                            I'm a software engineer from Nigeria who enjoys crafting simple, yet eleagant solutions to complex problems.
                            <br /> <br/>
                            I'm interested in both the frontend and backend stacks (backend heavy). A firm believer in trying new things, I like challenging myself by building problem-solving projects.
                            <br/> <br/>
                            In my free time, I enjoy reading books, playing chess, video games and doing other nerdy stuff. You can connect with me via my social handles.
                        </Main>
                    </Box>

                </Container>
            </Suspense>
        </ThemeProvider>
        
    )
}

export default AboutPage