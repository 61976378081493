export const Work = [
    {
        id:1,
        name:"ChatAfrica",
        description:"A web-based Flask application trained with data exclusively about Africa. The application uses GPT-3 to generate responses and renders it to the user in text format",
        tags:["Python","Flask","HTML"],
        
        demo:"https://github.com/coderboy-exe/ChatAfrica",
        github:"https://github.com/coderboy-exe/ChatAfrica"
    },
    {
        id:2,
        name:"Event planner",
        description:"A simple listing app built with Django",
        tags:["Python","Django"],
        
        demo:"https://github.com/coderboy-exe/event_planner",
        github:"https://github.com/coderboy-exe/event_planner"
        
    },
    {
        id:3,
        name:"Movie Search Application",
        description:"A Movie search application built using ReactJS and Styled Components. With realtime fetching of data from the TMDB API.",
        tags:["React","API","styledComponents"],
        
        demo:"http://movie-app-nu-seven.vercel.app/",
        github:"https://github.com/coderboy-exe/movie-app"
    },
    {
        id:4,
        name:"Django E-Commerce",
        description:"A simple e-commerce backend API built with Django Rest Framework",
        tags:["Python","Django"],
        
        demo:"https://github.com/coderboy-exe/leeba",
        github:"https://github.com/coderboy-exe/leeba"
       
    },{
        id:5,
        name:"Simple Shell",
        description:"A Simple Command Line Interpreter for Unix-Based Operating Systems",
        tags:["C","unix","shell"],
        
        demo:"https://github.com/coderboy-exe/simple_shell",
        github:"https://github.com/coderboy-exe/simple_shell"
        
    }
    
  
] 