import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import styled, { keyframes } from 'styled-components'
import HomeButton from "../subComponents/HomeButton";
import LogoComponent from "../subComponents/LogoComponent";
import SocialIcons from "../subComponents/SocialIcons";
import { Mail } from "./AllSvgs";
import Intro from "./Intro";
import Me from '../assets/Images/headshot.jpg';
import { motion } from "framer-motion";


const Contact = styled(NavLink)`
color: ${props => props.theme.text};
position: fixed;
top: 3rem;
padding: .1rem;
right: calc(1rem + 2vw);
text-decoration: none;
z-index: 5;
border-radius: .5rem;
transition: .2s;

&:hover{
    transform: scale(1.1);
    box-shadow: 0 0 2px 2px burlywood;
}

`

const Projects = styled(NavLink)`
color: ${props => props.theme.text};
position: fixed;
top: 42%;
left: calc(.2vw);
transform: rotate(-90deg) translate(-50%, 0);
text-decoration: none;
background-color: burlywood;
padding: .5rem;
border-radius: .5rem;
z-index: 5;
transition: .2s;

&:hover{
    transform: scale(1.1);
    box-shadow: 0 0 2px 2px burlywood;
}

`
const Skills = styled(NavLink)`
color: ${props => props.theme.text};
position: fixed;
top: 50%;
right: calc(1vw);
transform: rotate(90deg) translate(-50%, -50%);
text-decoration: none;
background-color: burlywood;
padding: .5rem;
border-radius: .5rem;
z-index: 5;
transition: .2s;

&:hover{
    transform: scale(1.1);
    box-shadow: 0 0 2px 2px burlywood;
}

`

const BottomBar = styled(motion.div)`
    position: fixed;
    bottom: 1rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;

    display: flex;
    justify-content: space-evenly;

`

const About = styled(NavLink)`
color: ${props => props.theme.text};
text-decoration: none;
background-color: burlywood;
padding: .5rem;
border-radius: .5rem;
z-index: 1;
transition: .2s;

&:hover{
    transform: scale(1.1);
    box-shadow: 0 0 2px 2px burlywood;
}
`

const Resume = styled.a`
color: ${props => props.theme.text};
text-decoration: none;
background-color: burlywood;
padding: .5rem;
border-radius: .5rem;
z-index: 5;
transition: .2s;

&:hover{
    transform: scale(1.1);
    box-shadow: 0 0 2px 2px burlywood;
}
`

const throb = keyframes`
0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`


const Center = styled.div`
    position: fixed;
    top: 90%;
    left: 92%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 1s ease;

    &>:first-child{
        animation: ${throb} infinite .8s cubic-bezier(0.42, 0, 0.58, 1);
    }

`

export const ContactNav = (props) => {
    return (
        <Contact {...props} target="_blank" to={{pathname: "mailto:coderboy.exe@gmail.com"}}>
            <Mail width={30} height={30} fill='currentColor' />
        </Contact>
  )
  
  }
export const SkillsNav = (props) => {
    return (
        <Skills to='/skills' {...props}>
            <h3>
                Skills
            </h3>
        </Skills>
  )
  
  }
export const ProjectsNav = (props) => {
    return (
        <Projects to='/projects' {...props}>
            <h3>
                Projects
            </h3>
        </Projects>
  )
  
  }

export const AboutNav = (props) => {
    return (
        <BottomBar
            initial={{
                y:200,
                transition: { type:'spring', duration: 1.5, delay: 1 }
            }}
            animate={{
                y:0,
                transition: { type:'spring', duration: 1.5, delay: 1 }
            }}
        >
            <About to='/about' {...props}>
                <h3>
                    About
                </h3>
            </About>
            <Resume target="_blank" href='https://docs.google.com/document/d/1xz24OnkukkRiIt19_M3MgGIhIkAHdAvpwx2StqDzVLI/edit?usp=sharing' {...props}>
                <h3>
                    Resume
                </h3>
            </Resume>
        </BottomBar>
  )
  
  }
export const IconNav = (props) => {
    return (
        <Center {...props}>
            <img src={Me} alt="me" style={{ borderRadius: "50%" }} width={80} height={80} fill='currentColor' />
        </Center>
  )
  
  }