import React, { lazy, useEffect, useState, Suspense } from "react";
import styled, { ThemeProvider } from 'styled-components';

import img from "../assets/Images/nasa-yZygONrUBe8-unsplash.jpg";
import {Work} from '../data/ProjectData';
import ProjectComponent from "./ProjectComponent";
import { lightTheme, darkTheme, mediaQueries } from "./Themes";
import { AboutNav, ContactNav, IconNav, SkillsNav } from "./Nav";
import { motion } from "framer-motion";
import Loader from "../subComponents/Loader";


// Lazy load imports
const HomeButton = lazy(() => import("../subComponents/HomeButton"));
const LogoComponent = lazy(() => import("../subComponents/LogoComponent"));
const SocialIcons = lazy(() => import("../subComponents/SocialIcons"));




const MainContainer = styled(motion.div)`
background-image: url(${img});
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;

`
const Container = styled.div`
background-color: ${props => `rgba(${props.theme.bodyRgba}, 0.7)`};
width: 100%;
height: 100vh;
position: relative;
padding-bottom: 5rem;
height: auto;
`

const Center = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding-top: 10rem;

    ${mediaQueries(30)`
        padding-top: 7rem;
    `};

`

const Grid = styled(motion.div)`
display: grid;
grid-template-columns: repeat(2, minmax(calc(10rem + 15vw), 1fr));
grid-gap: calc(1rem + 2vw);

    ${mediaQueries(50)`
        grid-template-columns: 100%;
    `};

`



const Projects = () => {
    return (
        <ThemeProvider theme={darkTheme}>
            <Suspense fallback={<Loader />}>
                <MainContainer>
                    <Container
                         key='skills'
                         initial={{ opacity: 0 }}
                         animate={{ opacity: 1, transition: { duration: 0.5 } }}
                         exit={{ opacity: 0, transition: { duration: 0.5 } }}
                    >
                        <LogoComponent theme='dark' />
                        <HomeButton />
                        <SocialIcons theme='dark' />
                        <ContactNav />
                        <SkillsNav theme='dark' />
                        <AboutNav theme='dark' />
                        <IconNav />
                        <Center>
                            <Grid
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }}
                            >
                                {
                                    Work.map((project) => {
                                    return <ProjectComponent key={project.id} project={project}  />
                                    })
                                }
                            </Grid>
                        </Center>

                    </Container>
                </MainContainer>
            </Suspense>
        </ThemeProvider>
        
    )
}

export default Projects